@import "~sanitize.css";
@import "theme";

* {
	outline: none;
}

main {
	position: absolute;
	top: $masthead-height;
  width: 100%;
  height: calc(100vh - #{$masthead-height});
}

.full-height {
  height: calc(100vh - #{$masthead-height});
}

.text {

	&-small {
		font-size: 85%;
		letter-spacing: .33px;
  }

	&-muted {
    // font-weight: 300;
    color: $gray-50;
  }

  &-bold {
    font-weight: 500;
  }

  &-truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

/* Rebranding Intrim Updates*/

.masthead-logo .top-nav-logo-img {
  height: 37px;
  width: auto;
  margin-top: -2px;
}


.top-section-list-item {
  height: auto !important;
  text-wrap: wrap;
}
// Emergency message container
.emergency-message-container {
  display: block;
  width: 100%;
  margin: 0px auto;
  margin-top: -2px;
  background: #D9F6FA;
  // position: relative;
  position: fixed;
  z-index: 1001;
}

.emergency-message-container .close {
  position: absolute;
  top: 10px;
  left: 12px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  align-items: center;
  width: 10px;
  height: 10px;
}

.banner-close-icon {
  display: block;
  font-weight: 400;
}

.emergency-message-text {
  text-align: center;
  color: #002677;
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  font-family: OptumSans, Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  line-height: 150%;
}

.emergency-message-title {
  margin-right: 5px;
  margin-left: 25px;
  // color: #5A5A5A;
    //   font-size: 16px;
    //   font-weight: 400;
  font-family: OptumSansC, OptumSans, Arial;
}

.emergency-message-text a {
  color: #002677;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  text-align: left;
  // font-size: 16px;
    //   font-weight: 400;
}

.emergency-message-text a i.banner-arrow-icon {
  margin-left: 5px;
}
/* Optum rebrancing */
.app-wrapper>div {
  background-color: #002677;
}

body .p-component {
  font-family: OptumSans, OptumSans-Regular !important;
}
/*=================== Optum buttons ===============*/
.optum-button-emphasized {
  font-family: OptumSans, OptumSans-Regular !important;
  color: #FFFFFF !important;
  background-color: #002677 !important;
  border-color: #002677 !important;
  border-radius: 999px !important;
    text-transform: none !important;
}

.optum-button-emphasized:hover {
  color: #FFFFFF !important;
  background-color: #001D5B !important;
  border-color: #001D5B !important;
}

.optum-button-emphasized:active {
  color: #FFFFFF !important;
  background-color: #00184D !important;
  border-color: #00184D !important;
}

.optum-button-emphasized:disabled {
  color: 6D6F70 !important;
  background-color: #F4F4F4 !important;
  border-color: #F4F4F4 !important;
}

.optum-button-standard {
  font-family: OptumSans, OptumSans-Regular !important;
  color: #002677 !important;
  background-color: #FFFFFF !important;
  border-color: #002677 !important;
  border-radius: 999px !important;
    text-transform: none !important;
}

.optum-button-standard:hover {
  color: #001D5B !important;
  background-color: #F4F4F4 !important;
  border-color: #001D5B !important;
}

.optum-button-standard:active {
  color: #00184D !important;
  background-color: #E5E5E6 !important;
  border-color: #00184D !important;
}

.optum-button-standard:disabled {
  color: 6D6F70 !important;
  background-color: #F4F4F4 !important;
  border-color: #F4F4F4 !important;
}

.optum-button-deemphasized {
  font-family: OptumSans, OptumSans-Regular !important;
  color: #323334 !important;
  background-color: #FFFFFF !important;
  border-color: #323334 !important;
  border-radius: 999px !important;
    text-transform: none !important;
}

.optum-button-deemphasized:hover {
  color: #000000 !important;
  background-color: #F4F4F4 !important;
  border-color: #000000 !important;
}

.optum-button-deemphasized:active {
  color: #000000 !important;
  background-color: #E5E5E6 !important;
  border-color: #000000 !important;
}

.optum-button-deemphasized:disabled {
  color: 6D6F70 !important;
  background-color: #F4F4F4 !important;
  border-color: #F4F4F4 !important;
}

.optum-button-destroy {
  font-family: OptumSans, OptumSans-Regular !important;
  color: #323334 !important;
  background-color: #FFFFFF !important;
  border-color: #323334 !important;
  border-radius: 999px !important;
    text-transform: none !important;
}

.optum-button-destroy:hover {
  color: #000000 !important;
  background-color: #F4F4F4 !important;
  border-color: #000000 !important;
}

.optum-button-destroy:active {
  color: #000000 !important;
  background-color: #E5E5E6 !important;
  border-color: #000000 !important;
}

.optum-button-destroy:disabled {
  color: 6D6F70 !important;
  background-color: #F4F4F4 !important;
  border-color: #F4F4F4 !important;
}