/**
 * Declares Change Healthcare font family styles
 */

@import "../mixins/_font-face";

// @include fontFace(
// 	"Core Sans",
// 	100,
// 	normal,
// 	"../fonts/coresansc-100"
// );

// @include fontFace(
// 	"Core Sans",
// 	100,
// 	italic,
// 	"../fonts/coresansc-100i"
// );

// @include fontFace(
// 	"Core Sans",
// 	200,
// 	normal,
// 	"../fonts/coresansc-200"
// );

// @include fontFace(
// 	"Core Sans",
// 	200,
// 	italic,
// 	"../fonts/coresansc-200i"
// );

/*************************************
// @include fontFace(
// 	"Core Sans",
// 	300,
// 	normal,
// 	"../fonts/coresansc-300"
// );

// @include fontFace(
// 	"Core Sans",
// 	300,
// 	italic,
// 	"../fonts/coresansc-300i"
// );

// @include fontFace(
// 	"Core Sans",
// 	400,
// 	normal,
// 	"../fonts/coresansc-400"
// );

// @include fontFace(
// 	"Core Sans",
// 	400,
// 	italic,
// 	"../fonts/coresansc-400i"
// );

// @include fontFace(
// 	"Core Sans",
// 	500,
// 	normal,
// 	"../fonts/coresansc-500"
// );

// @include fontFace(
// 	"Core Sans",
// 	500,
// 	italic,
// 	"../fonts/coresansc-500i"
// );

// @include fontFace(
// 	"Core Sans",
// 	600,
// 	normal,
// 	"../fonts/coresansc-600"
// );

// @include fontFace(
// 	"Core Sans",
// 	600,
// 	italic,
// 	"../fonts/coresansc-600i"
// );
*************************************/

// @include fontFace(
// 	"Core Sans",
// 	700,
// 	normal,
// 	"../fonts/coresansc-700"
// );

// @include fontFace(
// 	"Core Sans",
// 	700,
// 	italic,
// 	"../fonts/coresansc-700i"
// );

// @include fontFace(
// 	"Core Sans",
// 	800,
// 	normal,
// 	"../fonts/coresansc-800"
// );

// @include fontFace(
// 	"Core Sans",
// 	800,
// 	italic,
// 	"../fonts/coresansc-800i"
// );

// @include fontFace(
// 	"Core Sans",
// 	900,
// 	normal,
// 	"../fonts/coresansc-900"
// );

// @include fontFace(
// 	"Core Sans",
// 	900,
// 	italic,
// 	"../fonts/coresansc-900i"
// );

@include fontFace("OptumSans",
	400,
	normal,
	"../fonts/OptumSans-Regular"
);

@include fontFace("OptumSans",
	400,
	italic,
	"../fonts/OptumSans-RegularItalic"
);

@include fontFace("OptumSansBold",
	700,
	normal,
	"../fonts/OptumSans-Bold"
);

@include fontFace("OptumSansBold",
	700,
	italic,
	"../fonts/OptumSans-BoldItalic"
);

@include fontFace("OptumSansXBold",
	700,
	normal,
	"../fonts/OptumSans-XBold"
);

@include fontFace("OptumSansC",
	400,
	normal,
	"../fonts/OptumSansCond-Regular"
);

@include fontFace("OptumSansC",
	400,
	italic,
	"../fonts/OptumSansCond-RegularItalic"
);

@include fontFace("OptumSansCBold",
	700,
	normal,
	"../fonts/OptumSansCond-Bold"
);

@include fontFace("OptumSansCBold",
	700,
	italic,
	"../fonts/OptumSansCond-BoldItalic"
);