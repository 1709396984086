// Change Healthcare core style colors.
// Per Brand Standards Quick Guide.pdf Version 1 | 05182017

// PRIMARY COLOR PALETTE

	// White 60%
	$white: rgb(255, 255, 255);

	// Dark Blue 30%
	$blue: rgb(15, 15, 89);

	// Accent 5%
	$red: rgb(244, 42, 77);

	// Neutrals 5%
	$black: rgb(0, 0, 0);

	// Solid Grey
	$gray: rgb(55, 71, 79);
	$gray-70: rgb(115, 125, 133);
	$gray-50: rgb(155, 161, 169);
	$gray-30: rgb(195, 197, 205);
	$gray-15: rgb(225, 226, 230);

	// borders
	$gray-border: rgb(214, 218, 223);
	$gray-row-background: rgb(241, 242, 243);


// DIGITAL COLORS

	$digital-red: rgb(216, 64, 64);
	$digital-red-70: rgb(228, 121, 121);
	$digital-red-50: rgb(236, 159, 159);
	$digital-red-30: rgb(244, 197, 197);
	$digital-red-15: rgb(250, 226, 226);

	$digital-orange: rgb(255, 193, 7);
	$digital-orange-70: rgb(255, 211, 82);
	$digital-orange-50: rgb(255, 223, 132);
	$digital-orange-30: rgb(255, 235, 182);
	$digital-orange-15: rgb(255, 245, 219);

	$digital-green: rgb(68, 173, 0);
	$digital-green-70: rgb(125, 197, 78);
	$digital-green-50: rgb(163, 213, 130);
	$digital-green-30: rgb(201, 229, 182);
	$digital-green-15: rgb(228, 242, 219);


// ACCENT COLOR PALETTE

	$bright-blue: rgb(76, 198, 255);
	$bright-blue-70: rgb(130, 216, 255);
	$bright-blue-50: rgb(166, 228, 255);
	$bright-blue-30: rgb(202, 240, 255);
	$bright-blue-15: rgb(229, 248, 255);

	$magenta: rgb(234, 64, 172);
	$magenta-70: rgb(240, 121, 196);
	$magenta-50: rgb(244, 159, 212);
	$magenta-30: rgb(248, 197, 228);
	$magenta-15: rgb(252, 226, 242);

	$purple: rgb(134, 0, 217);
	$purple-70: rgb(168, 78, 229);
	$purple-50: rgb(192, 130, 237);
	$purple-30: rgb(216, 182, 245);
	$purple-15: rgb(236, 219, 250);

	$mint: rgb(70, 234, 167);
	$mint-70: rgb(127, 240, 194);
	$mint-50: rgb(154, 244, 212);
	$mint-30: rgb(203, 248, 230);
	$mint-15: rgb(229, 252, 243);
